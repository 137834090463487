<template>
  <div class="chat" ref="chat">
    <esmp-loader v-if="loading" fix />
    <div class="chat__header">
      <div class="chat__title">
        Общий чат заявки
      </div>
      <esmp-switch
        v-model="isNewCommentsFirst"
        shape="circular"
        label="Сначала новые"
      />
    </div>
    <div ref="body" class="chat__body">
      <template v-if="messageList && messageList.length">
        <div
          v-for="m in messageList"
          class="chat__message message-wrapper"
          :key="m.id"
        >
          <div :class="{'message': true, 'message_owner': isOwner(m.author)}">
            <esmp-user-avatar
              v-if="!isOwner(m.author)"
              :username="m.author.fullName"
              class="message__author-avatar"
            />
            <div class="message__body">
              <div class="message__author">
                {{ m.author.fullName }}
              </div>
              <div class="message__text" v-html="replaceLineBreak(m.body)" />
              <attachment-list
                v-if="m.attachments"
                :attachment-list="fileListMap(m.attachments)"
                :is-show-image="true"
                class="message__attachments"
              />
              <div class="message__created">
                {{ m.createdAt | dateFormat }}
              </div>
              <esmp-icon
                v-if="!isOwner(m.author)"
                class="message__corner message__corner_left"
                name="message-subtract-left"
              />
              <esmp-icon
                v-if="isOwner(m.author)"
                class="message__corner message__corner_right"
                name="message-subtract-right"
              />
            </div>
            <esmp-user-avatar
              v-if="isOwner(m.author)"
              :username="m.author.fullName"
              class="message__author-avatar"
            />
          </div>
        </div>
      </template>
      <div v-else>
        Сообщений нет
      </div>
    </div>
    <div ref="message" class="chat__footer">
      <div class="chat__footer-row">
        <template v-if="!disabled">
          <div class="chat__input">
            <esmp-input
              v-model="comment"
              label="Ваше сообщение"
              :disabled="loading"
              :options="{ type: 'textarea' }"
              :error-message="errorMessage"
              @enter-shift="setNewLine"
              @enter-exact="submit"
            />
          </div>
          <div class="chat__buttons">
            <esmp-upload
              v-model="files"
              :show-upload-list="false"
              multiple
              class="chat__upload-button"
              ref="uploader"
            >
              <esmp-icon name="attachment" />
            </esmp-upload>
            <span @click="submit">
              <esmp-icon
                class="chat__send-button"
                name="send-message"
              />
            </span>
          </div>
        </template>
        <template v-else>
          {{ disabledMessage }}
        </template>
      </div>
      <div v-if="!disabled" class="chat__footer-row">
        <attachment-list
          :attachment-list="fileList"
          with-delete-button
          @remove="onRemove"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MAX_COMMENT_LENGTH, ERROR_MESSAGES } from '@/constants/chat';
import { fileListMap, pasteFilesFromClipboard } from '@/helpers/attachments';
import getFileExtension from '@/helpers/getFileExtension';
import getFileFormat from '@/helpers/getFileFormat';
import replaceLineBreak from '@/utils/replaceLineBreak';
import AttachmentList from './AttachmentList.vue';

export default {
  name: 'Chat',
  components: { AttachmentList },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    creator: {
      type: Object,
      default: undefined,
    },
    customer: {
      type: Object,
      default: undefined,
    },
    ticketId: {
      type: [String, Number],
      default: undefined,
    },
    source: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
    },
    disabledMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isNewCommentsFirst: true,
      files: [],
      fileList: [],
      comment: '',
      loading: false,
      errorMessage: '',
    };
  },
  computed: {
    messageList() {
      return this.isNewCommentsFirst ? this.messages : [...this.messages].reverse();
    },
  },
  watch: {
    files: {
      handler() {
        this.fileList = this.files.map((el) => ({
          name: el.name,
          size: el.size,
          format: getFileFormat(el.name),
          extension: getFileExtension(el.name),
        }));
      },
      immediate: true,
      deep: true,
    },
    comment(val) {
      if (!val) this.errorMessage = ERROR_MESSAGES.isEmpty;
    },
    async messages(val) {
      if (val && val.length) {
        const chatBody = this.$refs.body;
        await this.$nextTick();
        chatBody.scrollTop = this.isNewCommentsFirst ? 0 : chatBody.scrollHeight;
      }
    },
  },
  methods: {
    fileListMap,
    replaceLineBreak,
    onRemove(fileName) {
      const file = this.files.find((el) => el.name === fileName);
      this.$refs.uploader.handleRemove(file);
    },
    isOwner(author) {
      if (this.creator) {
        if (this.creator.email) {
          if (this.creator.email.toLowerCase() === author.email?.toLowerCase()) {
            return true;
          }
        }

        if (this.creator.fullName) {
          if (this.creator.fullName.toLowerCase() === author.fullName.toLowerCase()) {
            return true;
          }
        }
      }

      if (this.customer) {
        if (this.customer.email) {
          if (this.customer.email.toLowerCase() === author.email?.toLowerCase()) {
            return true;
          }
        }

        if (this.customer.fullName) {
          if (this.customer.fullName.toLowerCase() === author.fullName.toLowerCase()) {
            return true;
          }
        }
      }
      return false;
    },
    submit() {
      if (this.loading) return false;
      if (!this.comment || this.comment.length > MAX_COMMENT_LENGTH) {
        if (!this.comment) {
          this.errorMessage = ERROR_MESSAGES.isEmpty;
        } else if (this.comment.length > MAX_COMMENT_LENGTH) {
          this.errorMessage = ERROR_MESSAGES.maxLength;
        }
        return false;
      }
      this.loading = true;
      const form = new FormData();
      form.append('source', this.source);
      form.append('comment', this.comment);
      this.files.forEach((file) => {
        form.append('attachmentList', file, file.name);
      });
      this.$API.ticket.addCommentToTicket(this.ticketId, form).then(({ data }) => {
        this.$refs.uploader.clearFiles();
        this.files = [];
        this.comment = '';
        this.$emit('submit', data);
      }).finally(() => {
        this.loading = false;
        this.errorMessage = '';
      });
      return undefined;
    },
    dragAndDropFiles(event) {
      event.preventDefault();
      for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
        this.files.push(event.dataTransfer.files[i]);
      }
      this.$refs.chat.classList.remove('chat--draggable');
    },
    setPreventDefault(event) {
      event.preventDefault();
      this.$refs.chat.classList.add('chat--draggable');
    },
    uploadFilesFromClipboard(event) {
      const blobs = pasteFilesFromClipboard(event);
      blobs.forEach((blob) => {
        this.files.push(blob);
      });
    },
    setNewLine() {
      this.comment = `${this.comment}\r\n`;
    },
  },
  mounted() {
    this.$refs.chat.addEventListener('dragover', this.setPreventDefault);
    this.$refs.chat.addEventListener('drop', this.dragAndDropFiles);
    document.addEventListener('paste', this.uploadFilesFromClipboard);
  },
  beforeDestroy() {
    this.$refs.chat.removeEventListener('dragover', this.setPreventDefault);
    this.$refs.chat.removeEventListener('drop', this.dragAndDropFiles);
    document.removeEventListener('paste', this.uploadFilesFromClipboard);
  },
};
</script>

<style lang="scss" scoped>
.chat {
  position: relative;
  margin: 32px 0 0 0;
  border: 1px solid rgba(232, 232, 238, 1);
  border-radius: 8px;
  overflow: hidden;

  @include for-size(phone-portrait-down) {
    margin: 32px -20px 0;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 8px 8px 0 0;
  }

  &--draggable {
    border-color: #855CE5;
  }
  &__header {
    padding: 18px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgba(232, 232, 238, 1);
    @include for-size(phone-portrait-down) {
      padding: 18px 16px;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
  }
  &__body {
    padding: 24px;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    @include for-size(phone-portrait-down) {
      padding: 16px;
    }
  }
  &__footer {
    padding: 16px 24px;
    background: rgba(244, 244, 245, 1);
    &-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin: 15px 0 0 0;
      &:first-child {
        margin: 0;
      }
      @include for-size(phone-portrait-down) {
        position: relative;
        display: block;
      }
    }
    ::v-deep .esmp-input .esmp-input-element {
      background-color: #fff;
    }
  }
  &__input {
    width: 100%;
    @include for-size(phone-portrait-down) {
      position: relative;
      display: block;
      ::v-deep .esmp-input .esmp-input-element {
        background-color: #fff;
        padding-right: 90px;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0 0 24px;
    svg {
      cursor: pointer;
    }
    @include for-size(phone-portrait-down) {
      position: absolute;
      top: -2px;
      right: 12px;
    }
  }
  &__upload-button {
    width: 24px;
    height: 24px;
  }
  &__send-button {
    margin: 0 0 0 10px;
  }
  &__message {
    margin: 15px 0 0 0;
    &:first-child {
      margin: 0;
    }
  }
}
.message {
  width: 100%;
  padding: 0 10% 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  &__text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
  &__author {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #2F9AFF;
  }
  &__author-avatar {
    margin: 0 10px 0 0;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    @include for-size(phone-portrait-down) {
      width: 32px;
      height: 32px;
    }
  }
  &_owner {
    padding: 0 0 0 10%;
    justify-content: flex-end;
    .message__author-avatar {
      margin: 0 0 0 10px;
    }
    .message__author {
      color: #9466FF;
    }
  }
  &__body {
    position: relative;
    padding: 8px 14px;
    border-radius: 8px;
    background: rgba(244, 244, 245, 1);
  }
  &__created {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: rgba(16, 24, 40, 0.6);
  }
  &__corner {
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 22px;
    &_left {
      left: -12px;
    }
    &_right {
      right: -12px;
    }
  }
}
</style>
