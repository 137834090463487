import pluralization from '@/utils/pluralization';

export const MAX_COMMENT_LENGTH = 100000;

const pluralizationSymbolWord = pluralization(MAX_COMMENT_LENGTH, ['символ', 'символа', 'символов']);

export const ERROR_MESSAGES = {
  isEmpty: 'Обязательное поле',
  maxLength: `Сообщение не должно превышать ${MAX_COMMENT_LENGTH} ${pluralizationSymbolWord}`,
};
